define("@woody-lite/core-session/components/modal-face-recognition-error", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@woody-lite/core-session/templates/modal-face-recognition-error"], function (_exports, _modal, _object, _modalFaceRecognitionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const ERRORS = {
    permissions: {
      title: 'label.faceRecognition.errors.permissions.title',
      text: 'label.faceRecognition.errors.permissions.text'
    },
    generic: {
      title: 'label.faceRecognition.errors.generic.title',
      text: 'label.faceRecognition.errors.generic.text'
    },
    cancelled: {
      title: 'label.faceRecognition.errors.cancelled.title',
      text: 'label.faceRecognition.errors.cancelled.text'
    }
  };
  let ModalFaceRecognitionErrorComponent = _exports.default = (_class = class ModalFaceRecognitionErrorComponent extends _modal.default {
    constructor() {
      var _this$veil;
      super(...arguments);
      _defineProperty(this, "layout", _modalFaceRecognitionError.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      const {
        errorType
      } = this.model.options;
      this.errorType = errorType;
      this.title = ERRORS[this.errorType].title;
      this.text = ERRORS[this.errorType].text;
    }
    closeModal() {
      return this.reject();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
});