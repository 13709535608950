define("@woody-lite/addon-feedback/models/aggregated-feedback", ["exports", "ember-object-data-model/models/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_STARS = 5;
  class AggregatedFeedbackModel extends _base.default {
    get percentages() {
      var _get;
      return (_get = (0, _object.get)(this, 'gradeDistribution')) === null || _get === void 0 ? void 0 : _get.map(_ref => {
        let {
          percentage
        } = _ref;
        return percentage;
      });
    }
    get ratings() {
      var _get2;
      return (_get2 = (0, _object.get)(this, 'gradeDistribution')) === null || _get2 === void 0 ? void 0 : _get2.map(_ref2 => {
        let {
          number
        } = _ref2;
        return number;
      });
    }
    get score() {
      return (0, _object.get)(this, 'averageScore');
    }
    get numComments() {
      return (0, _object.get)(this, 'number');
    }
    get valuations() {
      var _get3, _get4;
      return ((_get3 = (0, _object.get)(this, 'gradeDistribution')) === null || _get3 === void 0 ? void 0 : _get3.length) === MAX_STARS && ((_get4 = (0, _object.get)(this, 'gradeDistribution')) === null || _get4 === void 0 ? void 0 : _get4.reduce((res, _ref3) => {
        let {
          number = 0
        } = _ref3;
        return number + res;
      }, 0));
    }
  }
  _exports.default = AggregatedFeedbackModel;
});