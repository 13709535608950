define("ember-modal-service/components/modal", ["exports", "@ember/component", "@ember/string", "@ember/object", "ember-transition-end/utils/on-transition-end", "ember-modal-service/utils/css-transitions", "@ember/service", "@ember/runloop", "@glimmer/tracking", "@ember/test-waiters"], function (_exports, _component, _string, _object, _onTransitionEnd, _cssTransitions, _service, _runloop, _tracking, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const openWaiter = (0, _testWaiters.buildWaiter)('ember-modal-service:open-waiter');
  const closeWaiter = (0, _testWaiters.buildWaiter)('ember-modal-service:close-waiter');
  const isNotDestroyed = (target, key, descriptor) => {
    const targetMethod = descriptor.value;
    descriptor.value = function () {
      // istanbul ignore if: lifecycle check.
      if (this.isDestroyed || this.isDestroying) {
        return null;
      }
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return targetMethod.apply(this, args);
    };
    return descriptor;
  };
  let ModalComponent = _exports.default = (_dec = (0, _object.computed)('model.fullname'), _dec2 = (0, _object.computed)('visible'), (_class = class ModalComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _defineProperty(this, "attributeBindings", ['data-modal-show', 'data-id']);
      _defineProperty(this, "ariaRole", 'dialog');
      _initializerDefineProperty(this, "visible", _descriptor2, this);
    }
    get 'data-id'() {
      return (0, _string.camelize)(this.model.fullname);
    }
    get 'data-modal-show'() {
      return String(this.visible);
    }
    didInsertElement() {
      super.didInsertElement(...arguments);

      // [Service closes modal] Prevent creating an uncaught promise.
      this.model.promise.catch(() => {
        this._close();
      });
      (0, _runloop.next)(this, '_open');
    }
    _safeDidOpen() {
      this.didOpen && this.didOpen();
    }
    _open() {
      const element = this.element;
      this.visible = true;
      if ((0, _cssTransitions.hasTransitions)(element)) {
        const token = openWaiter.beginAsync();
        const callback = () => {
          openWaiter.endAsync(token);
          this._safeDidOpen();
        };
        (0, _onTransitionEnd.default)(this.element, callback, {
          transitionProperty: 'all',
          once: true,
          onlyTarget: true
        });
      } else {
        this._safeDidOpen();
      }
    }
    _close() {
      const element = this.element;

      // Close modal.
      this.visible = false;

      // Remove modal from array when transition ends.
      if ((0, _cssTransitions.hasTransitions)(element)) {
        const token = closeWaiter.beginAsync();
        const callback = () => {
          closeWaiter.endAsync(token);
          this._remove();
        };
        (0, _onTransitionEnd.default)(this.element, callback, {
          transitionProperty: 'all',
          once: true,
          onlyTarget: true
        });
      } else {
        this._remove();
      }
    }
    _remove() {
      this.modal._closeByModel(this.model);
    }
    resolve(data) {
      this._fullfillmentFn = () => this.model.resolve(data);
      this._close();
    }
    reject(error) {
      this._fullfillmentFn = () => this.model.reject(error);
      this._close();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this._fullfillmentFn && this._fullfillmentFn();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "visible", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, 'data-id', [_dec], Object.getOwnPropertyDescriptor(_class.prototype, 'data-id'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'data-modal-show', [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, 'data-modal-show'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_safeDidOpen", [isNotDestroyed], Object.getOwnPropertyDescriptor(_class.prototype, "_safeDidOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_open", [isNotDestroyed], Object.getOwnPropertyDescriptor(_class.prototype, "_open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_close", [isNotDestroyed], Object.getOwnPropertyDescriptor(_class.prototype, "_close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_remove", [isNotDestroyed], Object.getOwnPropertyDescriptor(_class.prototype, "_remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resolve", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resolve"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reject", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reject"), _class.prototype)), _class));
});