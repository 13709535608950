define("@woody-lite/core-session/models/client", ["exports", "@woody-lite/core-session/models/session/private", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/utils"], function (_exports, _private, _object, _service, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const AUTHENTICATION_TYPES = ['209'];
  let ClientModel = _exports.default = (_class = class ClientModel extends _private.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "_isPreactivated", _descriptor2, this);
      _initializerDefineProperty(this, "_isPreoperative", _descriptor3, this);
      _initializerDefineProperty(this, "_isAnonymous", _descriptor4, this);
    }
    get isPreactivated() {
      return (0, _utils.isNone)(this._isPreactivated) ? (0, _object.get)(this, 'role') === 'A' : this._isPreactivated;
    }
    set isPreactivated(value) {
      this._isPreactivated = value;
    }
    get isPreoperative() {
      return (0, _utils.isNone)(this._isPreoperative) ? (0, _object.get)(this, 'role') === 'P' : this._isPreoperative;
    }
    set isPreoperative(value) {
      this._isPreoperative = value;
    }
    get isAnonymous() {
      return (0, _utils.isNone)(this._isAnonymous) ? (0, _object.get)(this, 'otherUserInfo.CLIENTE_ANONIMO') === 'S' : this._isAnonymous;
    }
    set isAnonymous(value) {
      this._isAnonymous = value;
    }
    get endDoc() {
      const matches = (0, _object.get)(this, 'id').match(/\d(?!.*\d)/);
      return matches === null || matches === void 0 ? void 0 : matches[0];
    }
    get akamaiEnding() {
      const matches = (0, _object.get)(this, 'id').match(/\d{2}(?!.*\d)/);
      return matches === null || matches === void 0 ? void 0 : matches[0];
    }
    get refreshAllowed() {
      return !AUTHENTICATION_TYPES.includes((0, _object.get)(this, 'authenticationType'));
    }
    async keepAlive() {
      super.keepAlive(...arguments);
      if ((0, _object.get)(this, 'tsec') && this.refreshAllowed) {
        await this.enax.POST('refresh-granting-ticket');
      } else {
        this._clearTimers();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_isPreactivated", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_isPreoperative", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_isAnonymous", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});