define("@woody-lite/addon-feedback/services/feedback", ["exports", "@ember/service", "@woody-lite/core-session/decorators/session", "@glimmer/tracking"], function (_exports, _service, _session, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const AMBIENT_AUTO = 'auto';
  const DARK = 'dark';
  let FeedbackService = _exports.default = (_dec = (0, _session.default)('client'), (_class = class FeedbackService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "currentSession", _descriptor4, this);
      _initializerDefineProperty(this, "isRunning", _descriptor5, this);
    }
    // eslint-disable-next-line max-params
    async openModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars, additionalParams) {
      if (!feedbackUrl) {
        await this.modal.open('error-form');
        return false;
      }
      let data = await this.getDataForModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars);
      data = {
        ...data,
        ...additionalParams
      };
      return this.showFeedback(data);
    }
    async showFeedback(data) {
      let stars = 0;
      try {
        const rate = await this.modal.open('feedback', data);
        stars = rate;
      } catch {
        stars = 0;
      }
      return stars;
    }
    async getDataForModal(currentRoute, feedbackUrl, feedbackType, feedbackLang, stars) {
      const {
        isIOS,
        isBrowser,
        ambient,
        isDarkMode,
        isNative,
        deviceAmbient
      } = await this.provider.pull('device', 'getDevice');
      const lang = this.intl.primaryLocale;
      return {
        carry_darkmode: this.getDarkParam({
          ambient,
          isDarkMode,
          isNative,
          deviceAmbient
        }),
        carry_isnative: !isBrowser,
        stars_1: stars || 0,
        sc: this.getCleanRoute(currentRoute),
        ac: feedbackType,
        feedbackUrl,
        isIOS,
        carry_lang: lang,
        feedbackLang
      };
    }
    getDarkParam(_ref) {
      let {
        ambient,
        isDarkMode,
        isNative,
        deviceAmbient
      } = _ref;
      if (ambient === AMBIENT_AUTO && isNative) {
        return deviceAmbient === DARK;
      }
      if (ambient === AMBIENT_AUTO) {
        return isDarkMode;
      }
      return ambient === DARK;
    }
    getCleanRoute(currentRoute) {
      const splitBySlash = currentRoute.split('/');
      if (splitBySlash[1]) {
        return splitBySlash[1].substr(7);
      }
      return currentRoute;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isRunning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
});