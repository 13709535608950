define("@woody-mrs-potato/core-storages/services/cache", ["exports", "@ember/service", "@woody-mrs-potato/core-storages/utils/storage-for", "@ember/object", "@ember/utils"], function (_exports, _service, _storageFor, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wrap = _exports.default = _exports.checkBlock = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const checkBlock = block => {
    const now = Date.now();
    const expire = (0, _object.get)(block, 'meta.expire');

    // If not expire time, is a valid block.
    return (0, _utils.isEmpty)(expire) || expire > now;
  };
  _exports.checkBlock = checkBlock;
  function checkMeta(meta) {
    // If meta is not an object, set it as expire time.
    if (meta.constructor.name !== 'Object') {
      meta = {
        expire: meta
      };
    }

    // Convert moment expire time to timestamp.
    if ((0, _utils.typeOf)(meta.expire) === 'object') {
      meta.expire = +meta.expire;
    }

    // Do not store block if meta has expired.
    if (!checkBlock({
      meta
    })) {
      return null;
    }
    return meta;
  }
  const wrap = function (data) {
    let meta = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const block = {
      data,
      meta
    };
    block.meta.updated = Date.now();
    return block;
  };
  _exports.wrap = wrap;
  let CacheService = _exports.default = (_dec = (0, _storageFor.default)('cache'), (_class = class CacheService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "storage", _descriptor, this);
    }
    init() {
      const storage = this.storage;
      super.init(...arguments);

      // Clear expired keys
      Object.keys(storage.get('content')).forEach(key => {
        // Do not change to complex getter 'this.get(`storage.${key}`)', it will
        // not work because the storage getter sets the namesmace to the key
        const block = storage.get(key);
        if (!block || !checkBlock(block)) {
          storage.set(key);
        }
      });
    }
    set() {
      return this._set(...arguments);
    }
    unknownProperty() {
      return this._get(...arguments);
    }
    setUnknownProperty() {
      return this._set(...arguments);
    }
    keys() {
      return this.storage.keys().filter(key => {
        const block = this.getBlock(key);

        // Check block expiration date.
        return block && checkBlock(block);
      });
    }
    getBlock(key) {
      return (0, _object.get)(this.storage, key);
    }
    clear() {
      for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }
      if (keys.length === 0) {
        keys = this.keys();
      } else {
        // Flatten keys
        keys = [].concat(...keys);
      }
      // Do not change for this.storage.set(key), it will not fire
      // bindings and check for an empty value.
      keys.forEach(key => {
        (0, _object.set)(this, key, null);
      });
    }
    filter(callback, target) {
      const ret = [];
      this.keys().forEach((key, index, enumerable) => {
        const block = this.getBlock(key);
        if (callback.call(target, key, block, index, enumerable)) {
          ret.push(key);
        }
      });
      return ret;
    }
    filterBy() {
      return this.filter(this._iter.apply(this, arguments));
    }
    _iter(key, value) {
      const valueProvided = arguments.length === 2;
      return (k, block) => {
        const cur = (0, _object.get)(block, "meta.".concat(key));
        return valueProvided ? value === cur : Boolean(cur);
      };
    }
    _get(key) {
      const block = this.getBlock(key);

      // Check block expiration date.
      if (block && checkBlock(block)) {
        return (0, _object.get)(block, 'data');
      }
      return null;
    }
    _makeBlock(oldBlock, dataKey, value, meta) {
      let block;
      const isNestedProperty = (0, _utils.isPresent)(dataKey);

      // Build the block for complex properties.
      if (oldBlock && isNestedProperty) {
        const valueData = (0, _object.get)(oldBlock, 'data');

        // Update the complex key in the old object.
        (0, _object.set)(valueData, dataKey, value);
        value = valueData;
      }

      // If we have value or meta, store or update for a property.
      if ((0, _utils.isPresent)(value) || !value && meta) {
        if (meta) {
          meta = checkMeta(meta);

          // Do not store block if meta has expired.
          if (!meta) {
            return [false, false];
          }
        }

        // Make the object that is going to be stored.
        block = wrap(value, meta);

        // Merge both meta if old exists.
        if (oldBlock && oldBlock.meta) {
          block.meta = Object.assign({}, oldBlock.meta, block.meta);
        }
      }
      return [block, value];
    }
    _set(key, value, meta) {
      let rootKey = key;
      let dataKey = '';

      // Update value from root node.
      const keyParts = key.split('.');
      const isNestedProperty = keyParts.length > 1;

      // Check for a complex property set.
      if (isNestedProperty) {
        rootKey = keyParts[0];
        dataKey = keyParts.slice(1).join('.');
      }

      // Get old block to get the meta property.
      const oldBlock = this.getBlock(rootKey);

      // Build the block for complex properties.
      const [block, outputValue] = this._makeBlock(oldBlock, dataKey, value, meta);

      // Do not store anything if block is not valid.
      if (block === false && outputValue === false) {
        return this;
      }
      (0, _object.set)(this.storage, rootKey, block);
      if (!(0, _utils.isNone)(outputValue) || (0, _utils.isNone)(outputValue) && oldBlock) {
        // eslint-disable-next-line ember/classic-decorator-no-classic-methods
        this.notifyPropertyChange(key);
      }
      return this;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});