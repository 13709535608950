define("@woody-lite/core-session/components/modal-token-software", ["exports", "@ember/object", "@ember/service", "@woody-lite/core-foundation/components/modal", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/templates/modal-token-software", "@glimmer/tracking"], function (_exports, _object, _service, _modal, _clientEnax, _modalTokenSoftware, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HTTP_CONFLICT = 409;
  let ModalTokenSoftware = _exports.default = (_class = class ModalTokenSoftware extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalTokenSoftware.default);
      _initializerDefineProperty(this, "veil", _descriptor, this);
      _initializerDefineProperty(this, "alerter", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor5, this);
      _initializerDefineProperty(this, "isSmsEnabled", _descriptor6, this);
      _initializerDefineProperty(this, "isVoiceEnabled", _descriptor7, this);
      _initializerDefineProperty(this, "tokenSoftware", _descriptor8, this);
    }
    get signAlternateMethod() {
      return this.isVoiceEnabled || this.isSmsEnabled;
    }
    async didOpen() {
      super.didOpen(...arguments);
      this.tokenSoftware = await this.provider.pull('token-software', 'getTokenSoftware');
      this.isDisabled = false;
      this.send('signTransaction');
    }
    async _sendToken(otp) {
      const options = this.model.options;
      const resource = options.resource;
      if (options.factory === 'ENAX') {
        (0, _object.set)(resource, 'headers.authenticationdata', "otp-token=".concat(otp));
      }
      resource.veil = true;
      resource.error = true;
      const response = await resource.retry();
      return this.close(response);
    }
    async showOtherMethods() {
      let disableRetry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const knownResponsePairs = this.model.options.resource.knownResponsePairs;
      const hasSmsType = knownResponsePairs.find(pair => pair.type === _clientEnax.SIGNATURE_TYPES.SMS);
      const hasVoiceType = knownResponsePairs.find(pair => pair.type === _clientEnax.SIGNATURE_TYPES.VOICE);
      const privateConfig = await this.provider.pull('configuration', 'getPrivateConfiguration');
      const voiceOtpIsEnabled = (0, _object.get)(privateConfig, 'otpFallback.isActive');
      this.model.options.resource.veil = true;
      this.model.options.resource.error = true;
      this.isDisabled = disableRetry;
      this.isSmsEnabled = hasSmsType;
      this.isVoiceEnabled = voiceOtpIsEnabled && hasVoiceType;
    }
    sendSmsOtp() {
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.SMS
      });
    }
    sendVoiceOtp() {
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.VOICE
      });
    }
    async signTransaction() {
      const {
        tokenSoftware
      } = this;
      const challenge = this.model.options.resource.headers.authenticationchallenge;
      try {
        this.veil.add();
        this.isDisabled = true;
        const deviceToken = await (0, _object.get)(tokenSoftware, 'deviceToken');
        const {
          value
        } = await deviceToken.generate(challenge);
        await this._sendToken(value);
        this.isDisabled = false;
      } catch ({
        code,
        status
      }) {
        const isSigningWithFaceId = await (0, _object.get)(tokenSoftware, 'isSigningWithFaceId');
        if (code === 'CANCELED' && !isSigningWithFaceId) {
          this.isDisabled = false;
          return;
        }
        if (status !== HTTP_CONFLICT) {
          this.showOtherMethods(!isSigningWithFaceId);
        }
        const errorText = this.intl.t('error.signatureEasySign');
        this.alerter.add({
          text: errorText,
          type: 'float',
          visualType: 'error',
          accesibilityText: errorText
        });
      } finally {
        this.veil.remove();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isSmsEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isVoiceEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tokenSoftware", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendSmsOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendSmsOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendVoiceOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendVoiceOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "signTransaction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "signTransaction"), _class.prototype)), _class);
});