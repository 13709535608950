define("@woody-lite/core-session/resources/client-enax", ["exports", "@woody-lite/core-session/resources/session-enax", "@ember/utils", "@ember/object", "rsvp", "@woody-lite/core-session/errors/signature-selection", "@ember/service"], function (_exports, _sessionEnax, _utils, _object, _rsvp, _signatureSelection, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIGNATURE_TYPES = void 0;
  var _class, _descriptor, _descriptor2;
  /* eslint-disable complexity */
  /* eslint-disable no-magic-numbers, max-statements */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HTTP_FORBIDDEN = 403;
  const USER_CLIENT = 'usuario-cliente';
  const AUTHENTICATION_DATA_OTP = 'otp-sms';
  const FACE_RECOGNITION_SMS_OTP_MODAL_NAME = 'otpSmsModal';
  const SIGNATURE_TYPES = _exports.SIGNATURE_TYPES = {
    ACCESS_PASSWORD: '02',
    SMS: '05',
    VOICE: '35',
    CHALLENGE_ANSWER: '132',
    SAFE_TAP: '150',
    PINPOSITIONS: '170',
    SPID: '251',
    FACE_RECOGNITION: '259'
  };
  const SIGNATURE_PRIORITIES = [SIGNATURE_TYPES.FACE_RECOGNITION, SIGNATURE_TYPES.SPID, SIGNATURE_TYPES.SAFE_TAP, SIGNATURE_TYPES.CHALLENGE_ANSWER, SIGNATURE_TYPES.SMS, SIGNATURE_TYPES.PINPOSITIONS, SIGNATURE_TYPES.VOICE, SIGNATURE_TYPES.ACCESS_PASSWORD];
  const SIGNATURE_MODALS = {
    [SIGNATURE_TYPES.ACCESS_PASSWORD]: 'accessPasswordModal',
    [SIGNATURE_TYPES.CHALLENGE_ANSWER]: 'otpTokenSoftwareModal',
    [SIGNATURE_TYPES.SMS]: 'otpSmsModal',
    [SIGNATURE_TYPES.PINPOSITIONS]: 'otpPinPositionModal',
    [SIGNATURE_TYPES.VOICE]: 'otpVoiceModal',
    [SIGNATURE_TYPES.SAFE_TAP]: 'otpSafeTapModal',
    [SIGNATURE_TYPES.FACE_RECOGNITION]: 'otpFaceRecognitionModal'
  };
  const BIOMETRICS_LEVEL_CHANGE_EXCEPTIONS = ['SMC201500296', 'SMC201910159', 'SMC201700241', 'SMC201500268', 'SMGG20200106'];
  const SAFETAP_EXCEPTIONS = ['SMC201600442'];
  const SIGNATURE_CHOICES = [SIGNATURE_TYPES.FACE_RECOGNITION, SIGNATURE_TYPES.SAFE_TAP, SIGNATURE_TYPES.SMS, SIGNATURE_TYPES.PINPOSITIONS];
  function getAuthenticationHeaders(headers) {
    const authenticationType = headers.get('authenticationtype');
    const authenticationData = headers.get('authenticationdata');
    const authenticationState = headers.get('authenticationstate');
    const authenticationChallenge = headers.get('authenticationchallenge');
    return {
      authenticationType,
      authenticationData,
      authenticationState,
      authenticationChallenge
    };
  }
  function getSMC(response) {
    let smc = response.resource.smc;
    if (typeof smc === 'object') {
      smc = smc[response.request.method];
    }
    return smc;
  }
  function getResponsePairs(responseTypes, responseData, spidresultid) {
    const knownTypes = Object.values(SIGNATURE_TYPES);
    const responsePairs = responseTypes.map((type, index) => ({
      type,
      data: responseData[index]
    }));
    const knownResponsePairs = responsePairs.filter(pair => knownTypes.includes(pair.type));
    // Remove SPID from the list of known response pairs when spidresultid is not present.
    const filteredResponsePairs = knownResponsePairs.reduce((acc, pair) => {
      if (pair.type === SIGNATURE_TYPES.SPID && spidresultid) {
        acc.push(pair);
      } else if (pair.type !== SIGNATURE_TYPES.SPID) {
        acc.push(pair);
      }
      return acc;
    }, []);
    return [...filteredResponsePairs];
  }
  async function _findAsync(array, callback) {
    if (!array || !array.length) {
      throw new Error('Item not found');
    }
    async function iterate(index) {
      const item = array[index];
      if (!item) {
        throw new Error('Item not found');
      }
      return (await callback(item)) ? item : iterate(++index);
    }
    return await iterate(0);
  }
  function buildHeaders(headers, type, state, challenge) {
    // Add new type.
    headers.authenticationtype = type;

    // Remove data.
    delete headers.authenticationdata;

    // Append state if exists.
    if (state) {
      headers.authenticationstate = state;
    }
    if (challenge) {
      headers.authenticationchallenge = challenge;
    }
  }
  function resetHeaders(headers) {
    delete headers.authenticationtype;
    delete headers.authenticationdata;
    delete headers.authenticationstate;
    delete headers.authenticationchallenge;
  }
  function updateKnownResponsePairs(knownResponsePairs, responsePairs) {
    responsePairs.forEach(_ref => {
      let {
        type,
        data
      } = _ref;
      const knownPair = knownResponsePairs.find(pair => pair.type === type);
      knownPair.data = data;
    });
  }
  function retryLevelChange(labelConfirm) {
    return {
      retryLevelChange: true,
      labelConfirm
    };
  }
  function handleMethodChoices(pairs) {
    return pairs.filter(pair => {
      return SIGNATURE_CHOICES.includes(pair.type);
    });
  }
  async function filterAsync(array, callback) {
    const result = await Promise.all(array.map(item => callback(item)));
    return array.filter((_, index) => result[index]);
  }
  let ClientEnax = _exports.default = (_class = class ClientEnax extends _sessionEnax.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "modal", _descriptor2, this);
      _defineProperty(this, "otpSmsModal", 'sms');
      _defineProperty(this, "otpVoiceModal", 'voice');
      _defineProperty(this, "otpTokenSoftwareModal", 'token-software');
      _defineProperty(this, "accessPasswordModal", 'access-password');
      _defineProperty(this, "otpSafeTapModal", 'safe-tap-otp');
      _defineProperty(this, "otpFaceRecognitionModal", 'face-recognition');
      _defineProperty(this, "canSelecthAnotherType", true);
    }
    get privateConfig() {
      return this.provider.pull('configuration', 'getPrivateConfiguration');
    }
    async openLevelChange(response) {
      const {
        authenticationType,
        authenticationData,
        authenticationState,
        authenticationChallenge
      } = getAuthenticationHeaders(response.headers);
      if (!authenticationType) {
        throw new Error('You have not enough authorization to fulfill this request with the current security token.');
      }

      // Split by ';'
      const responseTypes = authenticationType.split(';');
      const responseData = authenticationData.split(';');
      let responseAuthentication = '';
      if (responseTypes.includes(SIGNATURE_TYPES.FACE_RECOGNITION)) {
        responseAuthentication = response.headers.get('authenticationresponse');
      }
      if (responseTypes.includes(SIGNATURE_TYPES.CHALLENGE_ANSWER)) {
        await this._handleBiometricsExceptions(response, responseTypes, responseData);
      }
      if (responseTypes.includes(SIGNATURE_TYPES.SAFE_TAP)) {
        this._handleSafeTapExceptions(response, responseTypes, responseData);
      }

      // Get all known response pairs.
      const responsePairs = getResponsePairs(responseTypes, responseData, this.spidresultid);
      let knownResponsePairs = this.knownResponsePairs;

      // Assign new response pairs.
      if (!knownResponsePairs) {
        knownResponsePairs = responsePairs;
      } else {
        // Update known response pairs.
        updateKnownResponsePairs(knownResponsePairs, responsePairs);
      }
      // Save known response pairs.
      this.knownResponsePairs = knownResponsePairs;
      if ((0, _utils.isEmpty)(knownResponsePairs)) {
        throw new Error("Unhandled authorizations types '".concat(responseTypes.join(', '), "'."));
      }

      // Select type using priority list.
      const selectedPair = await this.selectKnownResponsePair(responsePairs);
      const {
        type,
        data
      } = selectedPair;

      // Build headers
      buildHeaders(this.headers, type, authenticationState, authenticationChallenge);

      // When data is empty means that we need to request for a data sending selected type (f.e.: sms, voice.).
      if ((0, _utils.isEmpty)(responseAuthentication) && ((0, _utils.isEmpty)(data) || data === USER_CLIENT)) {
        return this.retry();
      }

      // When type is SPID, we need retry the request with the new headers.
      if (this.spidresultid && type === SIGNATURE_TYPES.SPID) {
        this.headers.authenticationdata = "".concat(data, "=").concat(this.spidresultid);
        return this.retry();
      }

      // When type is SAFE_TAP or FACE_RECOGNITION, we need to save the authenticationresponse header.
      if ([SIGNATURE_TYPES.SAFE_TAP, SIGNATURE_TYPES.FACE_RECOGNITION].includes(type)) {
        this.authenticationresponse = response.headers.get('authenticationresponse');
      }
      return this._handleLevelChangeModal(type, data, knownResponsePairs, authenticationData);
    }

    /* istanbul ignore next */
    async _handleBiometricsExceptions(response, responseTypes, responseData) {
      const privateConfig = await this.privateConfig;
      const skipBiometrics = (0, _object.get)(privateConfig, 'panicBiometricSignxception.isActive');
      const skipBiometricsForSMC = (0, _object.get)(privateConfig, 'biometricSignException.isActive') && BIOMETRICS_LEVEL_CHANGE_EXCEPTIONS.includes(getSMC(response));
      if (skipBiometrics || skipBiometricsForSMC) {
        const index = responseTypes.findIndex(type => type === SIGNATURE_TYPES.CHALLENGE_ANSWER);
        if (index >= 0) {
          responseTypes.splice(index, 1);
          responseData.splice(index, 1);
        }
      }
    }
    _handleSafeTapExceptions(response, responseTypes, responseData) {
      const skipSafeTap = SAFETAP_EXCEPTIONS.includes(getSMC(response));
      if (skipSafeTap) {
        const index = responseTypes.findIndex(type => type === SIGNATURE_TYPES.SAFE_TAP);
        if (index >= 0) {
          responseTypes.splice(index, 1);
          responseData.splice(index, 1);
        }
      }
    }
    async selectKnownResponsePair(responseTypes) {
      if (responseTypes.length === 1) {
        const pair = this.knownResponsePairs.find(_pair => _pair.type === responseTypes[0].type);
        if (pair) {
          return pair;
        }
        throw new Error("unknown response type '".concat(responseTypes[0], "'"));
      }
      let choices = handleMethodChoices(this.knownResponsePairs);
      if (choices.length > 1) {
        choices = await filterAsync(choices.map(choice => choice.type), this._checkType.bind(this));
        if (choices.length > 1) {
          const type = await this.openSignatureChoices(choices);
          const pair = this.knownResponsePairs.find(_pair => _pair.type === type);
          return pair;
        }
      }
      const priorityType = await _findAsync(SIGNATURE_PRIORITIES, this._checkType.bind(this));
      const pair = this.knownResponsePairs.find(_pair => _pair.type === priorityType);
      return pair ? (0, _rsvp.resolve)(pair) : (0, _rsvp.reject)(new Error());
    }
    async _checkType(type) {
      const pair = this.knownResponsePairs.find(_pair => _pair.type === type);
      if (!pair) {
        return false;
      }
      if (type === SIGNATURE_TYPES.CHALLENGE_ANSWER) {
        const tokenSoftware = await this.provider.pull('token-software', 'getTokenSoftware');
        const deviceToken = await (0, _object.get)(tokenSoftware, 'deviceToken');
        if (!(0, _object.get)(deviceToken, 'isActive')) {
          return false;
        }
        const isActive = await (0, _object.get)(tokenSoftware, 'isActive');

        // Notify the user easy sign is activated in another device
        if (!isActive) {
          await deviceToken.delete().catch(() => {});
          return false;
        }
        return isActive;
      }
      if (type === SIGNATURE_TYPES.SAFE_TAP) {
        return this.checkIfCanSafeTap();
      }
      if (type === SIGNATURE_TYPES.FACE_RECOGNITION) {
        return this.checkIfCanFaceRecognition();
      }
      return true;
    }
    async checkIfCanSafeTap() {
      const privateConfig = await this.privateConfig;
      if (!(0, _object.get)(privateConfig, 'safetap.isActive')) {
        return false;
      }
      const capabilities = await this.provider.pull('device', 'getCapabilities');
      const device = await this.provider.pull('device', 'getDevice');
      const hasHCE = (0, _object.get)(capabilities, 'hasHCE') && device.isAndroid;
      if (!hasHCE) {
        return false;
      }
      const validCards = await this.provider.fetch('valid-cards', 'getValidCards');
      return Boolean(validCards.length);
    }
    async checkIfCanFaceRecognition() {
      const privateConfig = await this.privateConfig;
      return (0, _object.get)(privateConfig, 'biometricEnrollmentWithFaceRecognition.isActive');
    }
    async openSignatureChoices(types) {
      let methods = SIGNATURE_PRIORITIES.filter(type => types.includes(type));

      // If has SAFE_TAP but not FACE_RECOGNITION, only can sign with SAFE_TAP.
      if (methods.includes(SIGNATURE_TYPES.SAFE_TAP) && !methods.includes(SIGNATURE_TYPES.FACE_RECOGNITION)) {
        this.canSelecthAnotherType = false;
        return SIGNATURE_TYPES.SAFE_TAP;
      }

      // If has FACE_RECOGNITION but not SAFE_TAP, only can sign with FACE_RECOGNITION.
      if (methods.includes(SIGNATURE_TYPES.FACE_RECOGNITION) && !methods.includes(SIGNATURE_TYPES.SAFE_TAP)) {
        this.canSelecthAnotherType = false;
        return SIGNATURE_TYPES.FACE_RECOGNITION;
      }

      // If has FACE_RECOGNITION and SAFE_TAP, can choice between FACE_RECOGNITION and SAFE_TAP.
      if (methods.includes(SIGNATURE_TYPES.FACE_RECOGNITION) && methods.includes(SIGNATURE_TYPES.SAFE_TAP)) {
        methods = methods.filter(m => m !== SIGNATURE_TYPES.SMS);
      }
      try {
        this.canSelecthAnotherType = true;
        return this.modal.open('signature-choices', {
          methods
        });
      } catch {
        throw new _signatureSelection.SignatureSelectionError();
      }
    }
    getModalName(type, authenticationData) {
      const modalProperty = SIGNATURE_MODALS[type];
      let modalName = this[modalProperty];
      if (authenticationData === AUTHENTICATION_DATA_OTP) {
        modalName = this[FACE_RECOGNITION_SMS_OTP_MODAL_NAME];
      }
      return modalName;
    }
    async _handleLevelChangeModal(type, data, responsePairs, authenticationData) {
      const modalOptions = {
        factory: 'ENAX',
        resource: this,
        type,
        data,
        responsePairs,
        canSelecthAnotherType: this.canSelecthAnotherType
      };
      const modalName = this.getModalName(type, authenticationData);
      if (!modalName) {
        throw new Error("Unknown modal for known type '".concat(type, "'."));
      }
      let ret;
      try {
        ret = await this.modal.open(modalName, modalOptions);
      } catch (reason) {
        if (reason !== null && reason !== void 0 && reason.chooseAnotherType) {
          resetHeaders(this.headers);
          return this.retry();
        }
        if (reason !== null && reason !== void 0 && reason.closeLevelChange) {
          throw reason;
        }
      }
      if (ret) {
        // Change selected signature type.
        if (ret.selectSignatureType) {
          buildHeaders(this.headers, ret.selectSignatureType);
          return this.retry();
        }

        // On retry level change, confirm and resend request.
        // This params are sent on retryLevelChange method.
        if (ret.retryLevelChange) {
          resetHeaders(this.headers);
          const retryConfirm = await this.modal.open('confirm', {
            messages: [{
              title: 'error.signature'
            }, {
              title: ret.labelConfirm
            }],
            labelCancel: 'label.cancel',
            labelOk: 'label.retry',
            verticalButtons: true
          });
          if (retryConfirm) {
            return this.retry();
          }
        }
      }
      return ret;
    }

    // eslint-disable-next-line complexity, max-statements
    handleGlobalFail(response) {
      const data = response.data;
      const {
        code
      } = data.messages[0];
      const responseAuthType = response.headers.get('authenticationtype');
      const requestAuthData = (0, _object.get)(this, 'headers.authenticationdata');
      switch (code) {
        case 'stateChangeGoOn':
        case 'forbidden':
          if (responseAuthType) {
            return {
              promise: this.openLevelChange(response)
            };
          }
          break;
        case 'invalidProcess':
          return {
            promise: retryLevelChange('error.signatureTimeout')
          };
        case 'badRequest':
          if (requestAuthData !== null && requestAuthData !== void 0 && requestAuthData.match(/password/)) {
            return {
              alert: 'error.wrongData'
            };
          }
          break;
        case 'invalidCredentials':
          if (requestAuthData) {
            // OTP_INCORRECTO
            if (requestAuthData !== null && requestAuthData !== void 0 && requestAuthData.match(/otp/)) {
              return {
                alert: 'error.wrongOtp'
              };
            }

            // PASSWORD_INCORRECTA
            if (requestAuthData !== null && requestAuthData !== void 0 && requestAuthData.match(/password/)) {
              return {
                alert: 'error.wrongData'
              };
            }
          }
          break;
        default:
        // nope
      }
      return super.handleGlobalFail(response);
    }
    handleLocalFail(response) {
      const data = response.data;
      const errorCode = data['error-code'];
      const numErrorCode = parseInt(errorCode, 10);

      // ENAX error control.
      // DOC: https://docs.google.com/a/bbva.com/file/d/0B772gIBcnNCbY1V1bU1PNzJNLVk/edit
      // Error-code is a string!
      switch (numErrorCode) {
        // 70 NO AUTORIZADO
        // Es necesario ponerse en contacto con la administración de la arquitectura
        // para iniciar un proceso administrativo de autorización de uso el servicio.
        // También salta en el primer paso de una firma de operaciones, en cuyo caso
        // vendrá acompañado de los tipos de firma permitidos.
        case 70:
          return {
            promise: this.openLevelChange(response)
          };

        // 999 Error, el proceso de firma continúa
        case 999:
          return {
            promise: this.openLevelChange(response)
          };

        // 362 ESTADO_AUTENTICACION_MULTIPASO_CADUCADO
        // El estado de la autenticación multipaso ha caducado
        case 362:
          return {
            promise: retryLevelChange('error.signatureTimeout')
          };

        // 363 ESTADO_AUTENTICACION_MULTIPASO_ANULADO_POR_REINTENTOS
        // El estado de la autenticación multipaso ha sido anulado por reintentos.
        case 363:
          return {
            promise: retryLevelChange('error.signatureMaxRetries')
          };
        // 168
        // El otp no coincide con el generado
        case 168:
          return {
            alert: 'error.wrongOtp'
          };
        default:
        // nope
      }

      // Handle 403 with authenticationdata filled.
      if (response.status === HTTP_FORBIDDEN && (0, _object.get)(this, 'headers.authenticationdata')) {
        return {
          alert: 'error.wrongData'
        };
      }
      return super.handleLocalFail(response);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});