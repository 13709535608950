define("@woody-lite/core-session/components/modal-sms", ["exports", "@ember/object", "@ember/service", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/templates/modal-sms", "@woody-lite/core-foundation/components/modal", "@ember/runloop", "@glimmer/tracking", "@woody-lite/core-session/decorators/session", "ember-concurrency-decorators", "ember", "ember-utils/utils/strings/obfuscate-phone", "ember-concurrency"], function (_exports, _object, _service, _clientEnax, _modalSms, _modal, _runloop, _tracking, _session, _emberConcurrencyDecorators, _ember, _obfuscatePhone, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const A11Y_NOTIFICATION_STEPS = ['90', '60', '30', '15', '10', '5'];
  let DEFAULT_DELAY = 15000;
  let DEFAULT_ALERT = 4000;
  let REPEAT_TASK_CONFIG = {
    maxWaitSeconds: 120,
    interval: 1000
  };

  // eslint-disable-next-line ember/no-ember-testing-in-module-scope
  if (_ember.default.testing) {
    DEFAULT_DELAY = 1;
    DEFAULT_ALERT = 1;
    REPEAT_TASK_CONFIG = {
      maxWaitSeconds: 2,
      interval: 1
    };
  }
  const DEFAULT_IMAGE = 'banners/firma.png';
  const ERROR_IMAGE = 'banners/firma_error.png';
  let ModalSms = _exports.default = (_dec = (0, _session.default)('client'), _dec2 = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), _dec3 = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), (_class = class ModalSms extends _modal.default {
    get remainingSecondsPercent() {
      return parseInt(this.remainingSeconds / REPEAT_TASK_CONFIG.maxWaitSeconds * 100, 10);
    }
    get remainingSecondsFormatted() {
      const remainingSeconds = this.remainingSeconds;
      const mm = parseInt(remainingSeconds / 60, 10);
      const ss = (remainingSeconds % 60).toString().padStart(2, '0');
      return "".concat(mm, ":").concat(ss);
    }
    get notificationTime() {
      return this.remainingSeconds > 0 && A11Y_NOTIFICATION_STEPS.includes(this.remainingSeconds.toString()) ? this.intl.t('label.a11ySmsRemainingTime', {
        remainingSeconds: this.remainingSeconds
      }) : '';
    }
    get retrySmsAccesibilityMessage() {
      let msg = this.intl.t('label.askNewSms');
      if (this.remainingSeconds === 0) {
        msg = "".concat(msg, " ").concat(this.intl.t('label.timeOverSms'));
      }
      return msg;
    }
    constructor() {
      var _this$veil;
      super(...arguments);

      // TODO: Remove into DB-230740
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "slinky", _descriptor4, this);
      _initializerDefineProperty(this, "veil", _descriptor5, this);
      _initializerDefineProperty(this, "currentSession", _descriptor6, this);
      _defineProperty(this, "layout", _modalSms.default);
      _initializerDefineProperty(this, "image", _descriptor7, this);
      _initializerDefineProperty(this, "errorImage", _descriptor8, this);
      _initializerDefineProperty(this, "key", _descriptor9, this);
      _initializerDefineProperty(this, "enableVoice", _descriptor10, this);
      _initializerDefineProperty(this, "phone", _descriptor11, this);
      _initializerDefineProperty(this, "enableRetry", _descriptor12, this);
      _initializerDefineProperty(this, "remainingSeconds", _descriptor13, this);
      _defineProperty(this, "timer", null);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
      this.initListeners();
    }
    async init() {
      super.init(...arguments);
      this.phone = this.model.options.phone || (await this.sessionPhone());
    }
    async didInsertElement() {
      super.didInsertElement(...arguments);
      const type = (0, _object.get)(this, 'model.options.type');
      const responsePairs = (0, _object.get)(this, 'model.options.responsePairs');
      const configuration = await this.getConfig();
      const isVoiceEnabled = (0, _object.get)(configuration, 'otpFallback.isActive') && (responsePairs === null || responsePairs === void 0 ? void 0 : responsePairs.find(pair => pair.type === _clientEnax.SIGNATURE_TYPES.VOICE)) && type !== _clientEnax.SIGNATURE_TYPES.VOICE;
      if (isVoiceEnabled) {
        this.timer = (0, _runloop.later)(this, 'set', 'enableVoice', true, DEFAULT_DELAY);
      }
      this.enableRetry = (0, _object.get)(configuration, 'retryOTP.isActive') && (responsePairs === null || responsePairs === void 0 ? void 0 : responsePairs.find(pair => pair.type === _clientEnax.SIGNATURE_TYPES.SMS)) && type === _clientEnax.SIGNATURE_TYPES.SMS;
      if (this.enableRetry) {
        this.retryCounter.perform();
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      this.teardownListeners();
      (0, _runloop.cancel)(this.timer);
      this.retryCounter.cancelAll();
    }
    didOpen() {
      super.didOpen(...arguments);
      this.element.querySelector('[data-id="txtSignatureKey"] input').focus();
    }
    async initListeners() {
      if (this.slinky.isConnected && (await this.canReadSMS())) {
        this.slinky.on('SMS.onMessage', this.onMessage);
      }
    }
    async teardownListeners() {
      if (this.slinky.isConnected && (await this.canReadSMS())) {
        this.slinky.off('SMS.onMessage', this.onMessage);
      }
    }
    async getConfig() {
      if (this.currentSession) {
        return this.provider.pull('configuration', 'getPrivateConfiguration');
      }
      return this.provider.pull('configuration', 'getPublicConfiguration');
    }
    async canReadSMS() {
      const deviceCapabilities = await this.provider.pull('device', 'getCapabilities');
      return (0, _object.get)(deviceCapabilities, 'canReadSMS');
    }
    async sessionPhone() {
      const contextualData = await this.provider.pull('customer', 'getContextualData');
      const phone = (0, _object.get)(this, 'model.options.resource.phone') || (contextualData === null || contextualData === void 0 ? void 0 : contextualData.phone) || '';
      return (0, _obfuscatePhone.default)(phone);
    }
    *submit() {
      const options = (0, _object.get)(this, 'model.options');
      const key = (0, _object.get)(this, 'key');
      const resource = options.resource;

      // Get promise.
      if (options.factory === 'ENAX') {
        (0, _object.set)(resource, 'headers.authenticationdata', "".concat(options.data, "=").concat(key));
      }
      try {
        this.veil.add();
        const data = yield resource.retry();
        return this.close(data);
      } catch (e) {
        if (e !== null && e !== void 0 && e.closeLevelChange) {
          return this.reject(e);
        }
        const error = (e === null || e === void 0 ? void 0 : e.alert) || 'Error';
        this.image = this.errorImage;
        return this.alerter.add({
          text: error,
          type: 'float',
          visualType: 'error',
          accesibilityText: error,
          duration: DEFAULT_ALERT
        });
      } finally {
        this.key = '';
        this.veil.remove();
      }
    }
    *retryCounter() {
      let duration = REPEAT_TASK_CONFIG.maxWaitSeconds;
      this.remainingSeconds = duration;
      do {
        yield (0, _emberConcurrency.timeout)(REPEAT_TASK_CONFIG.interval);
        this.remainingSeconds = --duration;
        this.remainingPercentage = this.remainingSeconds * 100 / REPEAT_TASK_CONFIG.maxWaitSeconds;
      } while (duration > 0);
    }
    retryOtp() {
      const options = (0, _object.get)(this, 'model.options');
      const resource = options.resource;
      if (options.factory === 'ENAX') {
        delete resource.headers.authenticationdata;
        delete resource.headers.authenticationstate;
      }
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.SMS
      });
    }
    async voiceOtp() {
      const result = await this.modal.open('confirm', {
        messages: [{
          title: this.intl.t('label.smsNotReceived')
        }, {
          title: this.intl.t('label.descriptionVoiceSMS')
        }]
      });
      if (result) {
        this.close({
          selectSignatureType: _clientEnax.SIGNATURE_TYPES.VOICE
        });
      }
    }
    onMessage(_, data) {
      const text = data === null || data === void 0 ? void 0 : data.text;
      if (text) {
        this.key = text;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_IMAGE;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "errorImage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return ERROR_IMAGE;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "key", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "enableVoice", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "phone", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "enableRetry", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "remainingSeconds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryCounter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "retryCounter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "retryOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "retryOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "voiceOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "voiceOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onMessage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onMessage"), _class.prototype)), _class));
});