define("@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles-card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <@slide.card @type="product" @boxLook={{true}}>
  	<div
  		{{did-insert this.didRenderComponent @slide.isSelected}}
  		{{did-update this.didRenderComponent @slide.isSelected}}
  	>
  		<CrmCampaign
  			@data-id={{concat @bubble.position "Bubbles-" @bubble.id}}
  			@model={{@bubble}}
  			@autoLoadActions={{false}}
  			@currentSession={{@currentSession}}
  			@transitionTo={{@transitionTo}}
  			@openModal={{@openModal}}
  			@onClose={{@onClose}}
  			@isSelected={{@slide.isSelected}}
  		/>
  	</div>
  </@slide.card>
  */
  {
    "id": "hrnMGDuW",
    "block": "[[[8,[30,1,[\"card\"]],null,[[\"@type\",\"@boxLook\"],[\"product\",true]],[[\"default\"],[[[[1,\"\\n\\t\"],[11,0],[4,[38,0],[[30,0,[\"didRenderComponent\"]],[30,1,[\"isSelected\"]]],null],[4,[38,1],[[30,0,[\"didRenderComponent\"]],[30,1,[\"isSelected\"]]],null],[12],[1,\"\\n\\t\\t\"],[8,[39,2],null,[[\"@data-id\",\"@model\",\"@autoLoadActions\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\",\"@isSelected\"],[[28,[37,3],[[30,2,[\"position\"]],\"Bubbles-\",[30,2,[\"id\"]]],null],[30,2],false,[30,3],[30,4],[30,5],[30,6],[30,1,[\"isSelected\"]]]],null],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]]]],[\"@slide\",\"@bubble\",\"@currentSession\",\"@transitionTo\",\"@openModal\",\"@onClose\"],false,[\"did-insert\",\"did-update\",\"crm-campaign\",\"concat\"]]",
    "moduleName": "@woody-lite/addon-crm-campaigns/components/carousel-futura-bubbles-card.hbs",
    "isStrictMode": false
  });
  let CarouselFuturaBubblesCardComponent = _exports.default = (_class = class CarouselFuturaBubblesCardComponent extends _component2.default {
    didRenderComponent() {
      if (this.args.slide.isSelected) {
        this.args.bubble.sendDisplayEvent();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didRenderComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didRenderComponent"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CarouselFuturaBubblesCardComponent);
});