define("@woody-lite/addon-iframe/components/iframe-aem", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-utils/utils/strings/unserialize-url", "@woody-lite/core-foundation/utils/serialize-query-params", "@woody-lite/core-foundation/utils/window-open", "@ember/utils", "@ember/array", "@ember/service", "@ember/application", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _unserializeUrl, _serializeQueryParams, _windowOpen, _utils, _array, _service, _application, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EVENTS_WITH_OWN_IMPLEMENTATION = _exports.ALLOWED_EVENTS = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (await this.src) as |src|}}
  	{{#if (is-fulfilled src)}}
  		<iframe
  			...attributes
  			title={{t "accessibility.iframeAem"}}
  			class="iframe-full"
  			src={{src}}
  			{{did-insert this.didRenderComponent}}
  		></iframe>
  	{{/if}}
  {{/let}}
  */
  {
    "id": "5ncKPrVP",
    "block": "[[[44,[[28,[37,1],[[30,0,[\"src\"]]],null]],[[[41,[28,[37,3],[[30,1]],null],[[[1,\"\\t\\t\"],[11,\"iframe\"],[17,2],[16,\"title\",[28,[37,4],[\"accessibility.iframeAem\"],null]],[24,0,\"iframe-full\"],[16,\"src\",[30,1]],[4,[38,5],[[30,0,[\"didRenderComponent\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[1]]]],[\"src\",\"&attrs\"],false,[\"let\",\"await\",\"if\",\"is-fulfilled\",\"t\",\"did-insert\"]]",
    "moduleName": "@woody-lite/addon-iframe/components/iframe-aem.hbs",
    "isStrictMode": false
  });
  const EVENTS_WITH_OWN_IMPLEMENTATION = _exports.EVENTS_WITH_OWN_IMPLEMENTATION = ['keepAlive', 'navigate', 'open', 'transferData'];
  const ALLOWED_EVENTS = _exports.ALLOWED_EVENTS = [...EVENTS_WITH_OWN_IMPLEMENTATION, 'domReady', 'back'];
  function hasOwnImplementation(eventAction) {
    return EVENTS_WITH_OWN_IMPLEMENTATION.includes(eventAction);
  }
  const formatVersion = (version, date) => "v".concat(version, ".").concat(date);

  /**
   * Component to render a full page iframe element to an external website. Allows
   * communication from iframe to parent using `Window.postMessage` API (see
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage), in which
   * case you'll need to define an array of domains allowed to send events (for
   * security purposes) and a hash mapping the name of the expected events to
   * their corresponding Ember actions
   *
   * @attribute src {String} URL to load
   * @attribute allowedDomains {Array} List of allowed domains to enable event handling
   * @attribute onLoad {Function} Action triggered when the iframe triggers the "load" event
   * @attribute onKeepAlive {Function} Action triggered when the iframe requests to refresh the session
   * @attribute navigate {Function} Action triggered when the iframe request to perform a transition
   * @attribute domReady {Function} Action triggered when the iframe content has been loaded
   * @attribute onOpen {Function} Action triggered when the iframe request to open a file
   * @attribute onTransferData {Function} Action triggered when the iframe sends some data
   *
   * @example
   * <Iframe
   *     @src='https://foo.jsp'
   *     @allowedDomains={{array 'https://m.bbva.es'}}
   *     @onLoad={{this.removeVeil}}
   *     @onTransferData={{this.handleData}}
   * />
   *
   * @class Iframe
   * @extends Ember.Component
   * @uses ComponentCommon
   * @public
   */
  let IframeAemComponent = _exports.default = (_class = class IframeAemComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_element", void 0);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "slinky", _descriptor3, this);
      this.config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      this.aemEndPoint = this.config.network.hosts.aem;
      this.eventHandlerBinding = this.handleEvent.bind(this);
    }
    get allowedDomains() {
      return (this.args.allowedDomains || []).concat(this.aemEndPoint);
    }
    get onLoad() {
      const fn = () => {};
      return this.args.onLoad || fn;
    }
    async getQueryParams() {
      const device = await this.provider.pull('device', 'getDevice');
      const {
        theme,
        ambient,
        deviceAmbient
      } = device;
      let urlTheme = theme;
      if (ambient === 'auto') {
        urlTheme = deviceAmbient;
      }
      const themeColorQueryParam = {
        theme: urlTheme
      };
      const params = {
        ...themeColorQueryParam
      };
      if (device.isNative) {
        if (device.isIOS) {
          const app = await this.provider.pull('application', 'getApplication');
          const {
            buildDate
          } = this.config;
          params.appVersion = formatVersion((0, _object.get)(app, 'version'), buildDate);
        }
        params.fontScale = device.fontScale;
      }
      return params;
    }
    get src() {
      return this.getSrc();
    }
    async getSrc() {
      let iframeSrc = this.args.src;
      if (iframeSrc) {
        const params = await this.getQueryParams();
        const queryParams = Object.assign((0, _unserializeUrl.default)(iframeSrc), params);
        const queryString = (0, _serializeQueryParams.default)(queryParams);
        const url = iframeSrc.split('?')[0];
        const hash = this.args.hash || '';
        iframeSrc = "".concat(url, "?").concat(queryString).concat(hash);
      }
      return iframeSrc;
    }
    didRenderComponent(element) {
      this._element = element;
      this.bindEventListeners();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.unbindEventListeners();
    }
    bindEventListeners() {
      window.addEventListener('message', this.eventHandlerBinding);
      this._element.addEventListener('load', this.onLoad);
    }
    unbindEventListeners() {
      window.removeEventListener('message', this.eventHandlerBinding);
      this._element.removeEventListener('load', this.onLoad);
    }
    shouldHandleEvent(name, origin) {
      const allowedDomains = this.allowedDomains;
      return Boolean((0, _utils.isPresent)(name) && (0, _utils.isPresent)(origin) && (0, _array.isArray)(allowedDomains) && allowedDomains.includes(origin));
    }
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch {
        return false;
      }
      return true;
    }
    handleEvent(event) {
      var _event$source;
      const eventData = (0, _object.get)(event, 'data');
      let eventAction = (0, _object.get)(event, 'data.channel');
      let data = (0, _object.get)(event, 'data.args.0');
      if (eventData && typeof eventData === 'string' && this.isJsonString(eventData)) {
        const dataParsed = JSON.parse(eventData);
        eventAction = (0, _object.get)(dataParsed, 'channel');
        data = (0, _object.get)(dataParsed, 'args.0');
      }
      const shouldHandle = this.shouldHandleEvent(eventAction, event.origin);
      const postMessage = event === null || event === void 0 ? void 0 : (_event$source = event.source) === null || _event$source === void 0 ? void 0 : _event$source.postMessage;
      if (shouldHandle && ALLOWED_EVENTS.includes(eventAction) && postMessage) {
        const responseCallback = postMessage.bind(event.source);
        let fn;
        if (hasOwnImplementation(eventAction)) {
          fn = this.getImplementation(eventAction, data);
        } else {
          fn = this.args[eventAction];
        }
        if (typeof fn !== 'function') {
          return;
        }
        fn(data, responseCallback);
      }
    }
    getImplementation(eventAction) {
      var _this$eventAction;
      return this.args[eventAction] || ((_this$eventAction = this[eventAction]) === null || _this$eventAction === void 0 ? void 0 : _this$eventAction.bind(this));
    }
    async keepAlive() {
      const device = await this.provider.pull('device', 'getDevice');
      device.onUserInteraction();
    }
    navigate(_ref) {
      let {
        url,
        queryParams
      } = _ref;
      this.router.transitionTo(url, {
        queryParams
      });
    }
    open(_ref2) {
      let {
        url
      } = _ref2;
      return (0, _windowOpen.windowOpen)(url, this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didRenderComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didRenderComponent"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IframeAemComponent);
});