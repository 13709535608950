define("@woody-lite/core-semaas/models/span", ["exports", "@ember/object", "ember-utils/utils/hash/guid", "@ember/debug", "@ember/utils", "@ember/application", "@woody-lite/core-semaas/constants", "@woody-lite/core-semaas/decorators/evented"], function (_exports, _object, _guid, _debug, _utils, _application, _constants, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function getPresentKeys(object) {
    const copy = {};
    for (const [key, value] of Object.entries(object)) {
      if ((0, _utils.isPresent)(value)) {
        copy[key] = value;
      }
    }
    return copy;
  }
  let Span = _exports.default = (_dec = (0, _object.computed)('parent.traceId'), _dec2 = (0, _object.computed)('parent.{mrId,spanId}', 'config.namespace'), _dec3 = (0, _object.computed)('_startTime', 'offset'), _dec4 = (0, _object.computed)('_stopTime', 'offset'), (0, _evented.default)(_class = (_class2 = class Span extends _object.default {
    get config() {
      const {
        semaas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return semaas;
    }
    get traceId() {
      return (0, _object.get)(this, 'parent.traceId') || (0, _guid.default)();
    }
    get parentSpan() {
      const {
        parent
      } = this;
      if (!parent) {
        return null;
      }
      return "ns/".concat(this.config.namespace, "/mrs/").concat(parent.mrId, "/spans/").concat(parent.spanId);
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "spanId", (0, _guid.default)());
      _defineProperty(this, "parent", null);
      _defineProperty(this, "name", null);
      _defineProperty(this, "mrId", null);
      _defineProperty(this, "offset", 0);
      _defineProperty(this, "properties", {});
      _defineProperty(this, "_startTime", null);
      _defineProperty(this, "_endTime", null);
      this.serializeProperties = ['mrId', 'name', 'parentSpan', 'properties', 'spanId', 'traceId', 'startDate', 'finishDate'];
    }
    start() {
      (false && !((0, _utils.isEmpty)(this._startTime)) && (0, _debug.assert)('Span is already started', (0, _utils.isEmpty)(this._startTime)));
      (0, _object.set)(this, '_startTime', performance.now());
      this.trigger('start');
    }
    stop() {
      (false && !((0, _utils.isPresent)(this._startTime)) && (0, _debug.assert)('Span is not started yet', (0, _utils.isPresent)(this._startTime)));
      (false && !((0, _utils.isEmpty)(this._stopTime)) && (0, _debug.assert)('Span is already stopped', (0, _utils.isEmpty)(this._stopTime)));
      (0, _object.set)(this, '_stopTime', performance.now());
      this.trigger('stop');
    }
    calculateTimeOffset(delta) {
      const start = performance.timing.navigationStart || 0;
      const time = (start + delta) * _constants.MILLISECONDS_TO_NANO;
      return time + this.offset;
    }
    get startDate() {
      const startTime = this._startTime;
      if ((0, _utils.isNone)(startTime)) {
        return null;
      }
      return this.calculateTimeOffset(startTime);
    }
    get finishDate() {
      const stopTime = this._stopTime;
      if ((0, _utils.isNone)(stopTime)) {
        return null;
      }
      return this.calculateTimeOffset(stopTime);
    }
    addProperties(content) {
      (0, _object.setProperties)(this.properties, content);
    }
    toJSON() {
      const {
        serializeProperties
      } = this;
      // eslint-disable-next-line ember/classic-decorator-no-classic-methods
      let object = (0, _object.getProperties)(this, serializeProperties);
      object = getPresentKeys(object);
      for (const [key, value] of Object.entries(object)) {
        if ((0, _utils.typeOf)(value) === 'object') {
          object[key] = getPresentKeys(value);
        }
      }
      return object;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "traceId", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "traceId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "parentSpan", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "parentSpan"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "startDate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "startDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "finishDate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "finishDate"), _class2.prototype)), _class2)) || _class);
});