define("@woody-lite/addon-iframe/components/iframe-external", ["exports", "@ember/component", "@ember/template-factory", "@woody-lite/addon-iframe/components/iframe-aem", "@ember/utils", "@ember/object"], function (_exports, _component, _templateFactory, _iframeAem, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <iframe
  	title={{@accessibilityTitle}}
  	class="iframe-full"
  	{{did-insert this.didRenderComponent}}
  	{{did-update this.eventObserver @sendEvents}}
  	src={{this.src}}
  	...attributes
  ></iframe>
  */
  {
    "id": "V0gGrbS9",
    "block": "[[[11,\"iframe\"],[16,\"title\",[30,1]],[24,0,\"iframe-full\"],[16,\"src\",[30,0,[\"src\"]]],[17,2],[4,[38,0],[[30,0,[\"didRenderComponent\"]]],null],[4,[38,1],[[30,0,[\"eventObserver\"]],[30,3]],null],[12],[13]],[\"@accessibilityTitle\",\"&attrs\",\"@sendEvents\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "@woody-lite/addon-iframe/components/iframe-external.hbs",
    "isStrictMode": false
  });
  let IframeExternalComponent = _exports.default = (_class = class IframeExternalComponent extends _iframeAem.default {
    get src() {
      return this.args.src;
    }
    get eventNamePath() {
      return 'data.action';
    }
    get eventCode() {
      return 'data.code';
    }
    hasMatchingAction(_action) {
      return Object.keys(this.args.events).includes(_action);
    }
    handleEvent(event) {
      if (!this.args.events || !this.shouldHandleEvent(event)) {
        return;
      }
      const dataAction = event.data.action;
      if (this.hasMatchingAction(dataAction)) {
        this.args.events[dataAction](event.data);
      }
      const dataCode = event.data.code;
      if (this.hasMatchingAction(dataCode)) {
        this.args.events[dataCode](event.data);
      }
    }
    shouldHandleEvent(event) {
      const name = (0, _object.get)(event, this.eventNamePath);
      const code = (0, _object.get)(event, this.eventCode);
      return (0, _utils.isPresent)(name) || (0, _utils.isPresent)(code);
    }
    didRenderComponent() {
      super.didRenderComponent(...arguments);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "didRenderComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didRenderComponent"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IframeExternalComponent);
});