define("@woody-lite/addon-discreet-mode/services/discreet-mode", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DISCREET_MODE = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DISCREET_MODE = _exports.DISCREET_MODE = {
    PERMANENT: 'permanent',
    TEMPORAL: 'temporal',
    DESACTIVATED: 'desactivate'
  };
  let DiscreetModeService = _exports.default = (_class = class DiscreetModeService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "sessionStorage", _descriptor, this);
      _initializerDefineProperty(this, "applicationStorage", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "discreetModeActive", _descriptor4, this);
    }
    async getDiscreetMode() {
      const temporal = await this.sessionStorage.getItem('discreetMode');
      const permanent = await this.applicationStorage.getItem('discreetMode');
      this.discreetMode = temporal || permanent;
      if (temporal) {
        return DISCREET_MODE.TEMPORAL;
      }
      if (permanent) {
        return DISCREET_MODE.PERMANENT;
      }
      return DISCREET_MODE.DESACTIVATED;
    }
    async setDiscreetMode(value) {
      const mode = {
        [DISCREET_MODE.TEMPORAL]: false,
        [DISCREET_MODE.PERMANENT]: false,
        [DISCREET_MODE.DESACTIVATED]: false
      };
      mode[value] = true;
      await this.sessionStorage.setItem('discreetMode', mode.temporal);
      await this.applicationStorage.setItem('discreetMode', mode.permanent);
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
    }
    async updateDiscreetMode() {
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
    }
    routeDidChange() {
      this.updateDiscreetMode();
    }
    async attach() {
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
      this.router.on('routeDidChange', this, 'routeDidChange');
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off('routeDidChange', this, 'routeDidChange');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "discreetModeActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDiscreetMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDiscreetMode"), _class.prototype)), _class);
});