define("@woody-lite/addon-tasks/repositories/tasks", ["exports", "ember-object-data-model/repositories/repository", "@woody-lite/core-session/decorators/session", "@ember/service", "@ember/object", "@ember/application", "@woody-lite/addon-tasks/models/tasks", "@woody-lite/addon-tasks/errors/task"], function (_exports, _repository, _session, _service, _object, _application, _tasks, _task) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const DEFAULT_TASK = 'A2E9A9B6-C939-1574-7CEE-86A69B7594DC';
  const TASK_ALREADY_ACCEPTED_ERROR_CODE = 'bpmTaskTaskIsAcceptedOther';
  const TIMEOUT_CUF = 'DISCARDED';
  const TASKS_PRIORITY = {
    KGPE_TASK_001: 1,
    default: 0
  };
  function taskPriority(task) {
    return TASKS_PRIORITY[(0, _object.get)(task, 'typeId')] || TASKS_PRIORITY.default;
  }
  function sortTaskList(taskList) {
    return taskList.sort((task1, task2) => taskPriority(task1) - taskPriority(task2));
  }
  let TasksRepository = _exports.default = (_dec = (0, _session.default)('client'), (_class = class TasksRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "veil", _descriptor3, this);
      _initializerDefineProperty(this, "currentSession", _descriptor4, this);
    }
    get privateConfiguration() {
      return this.provider.pull('configuration', 'getPrivateConfiguration');
    }
    async findAnnouncements() {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const privateConfig = await this.privateConfiguration;
      let data = {};
      if ((0, _object.get)(privateConfig, 'taskKife.isActive')) {
        try {
          var _this$currentSession;
          data = await this.enax.GET('announcements', {
            params: {
              $filter: "showOnlyNoReadCount==true;clientId==".concat((_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id, ";(product.id==9999999999)")
            }
          });
        } catch {
          //
        }
      }
      return this.createEntry('kife-counter', "".concat(customer.id, "-NO_READS"), data);
    }
    async findAll() {
      const privateConfig = await this.privateConfiguration;
      let data = {};
      let list = [];
      if ((0, _object.get)(privateConfig, 'myMailboxTasks.isActive')) {
        var _this$currentSession2, _data;
        const taskId = await this._getTaskId();
        const params = {
          customerId: (_this$currentSession2 = this.currentSession) === null || _this$currentSession2 === void 0 ? void 0 : _this$currentSession2.id,
          $filter: "(typeid==".concat(taskId, ")")
        };
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:task');
        data = await this.enax.GET('customer-business-tasks', {
          params
        });
        list = (_data = data) === null || _data === void 0 ? void 0 : _data.listTasks.map(task => {
          const normalizeMessage = normalizer.normalize(task);
          return this.createEntry('task', normalizeMessage.properties.id, normalizeMessage.properties);
        });
      }
      return _tasks.default.create({
        content: sortTaskList(list),
        _data: data
      });
    }
    async _acceptTask(task) {
      try {
        var _pendignTask$status;
        this.veil.add();
        const cuf = (0, _object.get)(task, 'customId');
        const pendignTask = await this.enax.GET('get-pending-signature-cuf', {
          cuf
        });
        if (((_pendignTask$status = pendignTask.status) === null || _pendignTask$status === void 0 ? void 0 : _pendignTask$status.id) !== TIMEOUT_CUF) {
          var _pendignTask$contract, _pendignTask$contract2, _this$currentSession3;
          (0, _object.set)(task, 'detailTask', pendignTask);
          (0, _object.set)(task, 'relatedContractId', (pendignTask === null || pendignTask === void 0 ? void 0 : (_pendignTask$contract = pendignTask.contracts) === null || _pendignTask$contract === void 0 ? void 0 : (_pendignTask$contract2 = _pendignTask$contract[0]) === null || _pendignTask$contract2 === void 0 ? void 0 : _pendignTask$contract2.id) || '');
          const customerId = (_this$currentSession3 = this.currentSession) === null || _this$currentSession3 === void 0 ? void 0 : _this$currentSession3.id;
          const acceptTaskDTO = {
            acceptedByList: [customerId],
            user: customerId
          };
          await this.enax.PUT('task-accept', acceptTaskDTO, {
            taskId: task.id
          });
          task.sync();
          return task;
        }
      } catch (error) {
        var _error$data;
        let errorLabel = 'error.serviceTemporaryUnavailable';
        if ((error === null || error === void 0 ? void 0 : (_error$data = error.data) === null || _error$data === void 0 ? void 0 : _error$data['error-code']) === TASK_ALREADY_ACCEPTED_ERROR_CODE) {
          errorLabel = 'error.alreadyAcceptedTask';
        }
        throw new _task.default({
          errorLabel,
          errorSeverity: 'error'
        });
      } finally {
        this.veil.remove();
      }
      throw new _task.default({
        errorSeverity: 'info',
        errorLabel: 'info.canceledTask'
      });
    }
    async _getTaskId() {
      const privateConfig = await this.provider.pull('configuration', 'getPrivateConfiguration');
      const customerTasksFilters = (0, _object.get)(privateConfig, 'customerTasksFilters');
      if (customerTasksFilters) {
        const activeTasks = customerTasksFilters.filter(task => task.isActive);
        if ((activeTasks === null || activeTasks === void 0 ? void 0 : activeTasks.length) > 1) {
          const res = activeTasks.slice(1).reduce((tasks, _ref) => {
            let {
              typeId
            } = _ref;
            return "".concat(tasks, "typeid==").concat(typeId, ",");
          }, "".concat((0, _object.get)(activeTasks, '0.typeId'), ","));
          return res.slice(0, -1);
        }
        if ((activeTasks === null || activeTasks === void 0 ? void 0 : activeTasks.length) === 1) {
          return (0, _object.get)(activeTasks[0], 'typeId');
        }
      }
      return DEFAULT_TASK;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});