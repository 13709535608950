define("@woody-lite/core-session/repositories/customer", ["exports", "ember-object-data-model/repositories/repository", "@woody-lite/core-session/models/empty", "@woody-lite/core-session/decorators/session", "@ember/service", "@ember/object", "@ember/application"], function (_exports, _repository, _empty, _session, _service, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const LAST_CLIENT_KEY = 'last-client';
  let CustomerRepository = _exports.default = (_dec = (0, _session.default)('client'), (_class = class CustomerRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "applicationStorage", _descriptor, this);
      _initializerDefineProperty(this, "enax", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "currentSession", _descriptor4, this);
    }
    async getContextualData() {
      var _this$currentSession;
      const device = await this.provider.pull('device', 'getDevice');
      const id = (_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id;
      if (!id) {
        return _empty.default.create();
      }
      if ((0, _object.get)(device, 'isNative')) {
        return this._getNativeContextualData(id);
      }
      return this._getCustomerLogins(id);
    }
    async getContextualDataHTML() {
      const id = (0, _object.get)(this.currentSession, 'id');
      return this._getCustomerLogins(id);
    }
    async _getNativeContextualData(customerId) {
      var _result;
      const response = await this.provider.pull('slinky', 'Session.getContextualDataResponse');
      let result = (0, _object.get)(response, 'result');
      if ((_result = result) !== null && _result !== void 0 && _result.data) {
        var _result2;
        const normalizer = (0, _application.getOwner)(this).lookup('normalizer:customerLogins');
        result = normalizer.normalize((_result2 = result) === null || _result2 === void 0 ? void 0 : _result2.data);
      }
      return this.createEntry('customer', customerId, {
        ...result,
        status: (0, _object.get)(response, 'status'),
        headers: (0, _object.get)(response, 'headers')
      });
    }
    get customerService() {
      var _config$session;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return ((_config$session = config.session) === null || _config$session === void 0 ? void 0 : _config$session.customerService) || 'customer-logins';
    }
    async _getCustomerLogins(customerId) {
      const data = await this.enax.GET(this.customerService, {
        params: {
          customerId
        }
      });
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:customerLogins');
      const normalizedData = normalizer.normalize(data);
      return this.createEntry('customer', customerId, normalizedData);
    }
    async getLastClient() {
      const lastClient = await this.applicationStorage.getItem(LAST_CLIENT_KEY);
      if (!lastClient) {
        return _empty.default.create();
      }
      return this.createEntry('last-client', lastClient.id, lastClient);
    }
    async storeLastClient() {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const {
        id,
        username
      } = (0, _object.getProperties)(customer.session, 'id', 'username');
      const lastClient = {
        id,
        name: (0, _object.get)(customer, 'customer.name'),
        surname2: (0, _object.get)(customer, 'customer.mothersLastName'),
        surname: (0, _object.get)(customer, 'customer.lastName'),
        username
      };
      await this.applicationStorage.setItem(LAST_CLIENT_KEY, lastClient);
      return this.provider.fetch('customer', 'getLastClient');
    }
    async deleteLastClient() {
      var _window$HtmlSessionId;
      const lastClient = await this.provider.fetch('customer', 'getLastClient');
      lastClient.unsync();
      await this.applicationStorage.removeItem(LAST_CLIENT_KEY);
      await ((_window$HtmlSessionId = window.HtmlSessionId) === null || _window$HtmlSessionId === void 0 ? void 0 : _window$HtmlSessionId.refresh());
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});