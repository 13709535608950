define("@woody-lite/addon-conversations/models/thread", ["exports", "ember-object-data-model/models/base", "@ember/object", "moment", "@ember/template"], function (_exports, _base, _object, _moment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CLIENT_TYPE = 'C';
  const CRM_NO_REPLY_ID = ['9'];
  const NO_GESTOR = ['9', '6'];
  const DEFAULT_SENDER_IMAGE = 'gestor.png';
  const NO_GESTOR_IMAGE = 'entity/card/enabled/170.png';
  class ThreadModel extends _base.default {
    get preview() {
      return (0, _object.get)(this, 'messages.content.lastObject.text');
    }
    get stickyCreationDate() {
      return (0, _moment.default)((0, _object.get)(this, 'lastUpdate')).format('LL');
    }
    get canReply() {
      const typeId = (0, _object.get)(this, 'type.id');
      return !CRM_NO_REPLY_ID.includes(typeId);
    }
    get senderImage() {
      const typeId = (0, _object.get)(this, 'type.id');
      return NO_GESTOR.includes(typeId) ? NO_GESTOR_IMAGE : DEFAULT_SENDER_IMAGE;
    }
    get textFormatted() {
      const [first, second] = this.preview.split('.');
      let string = '';
      if (first && second) {
        string = [first, second].join('.');
      } else if (first) {
        string = first;
      }
      return (0, _template.htmlSafe)(string);
    }
    get pendingMessages() {
      return (0, _object.get)(this, 'customerPendingMessages') || (0, _object.get)(this, 'messages.content').some(m => !(0, _object.get)(m, 'readed') && (0, _object.get)(m, 'sender.type.id') !== CLIENT_TYPE);
    }
  }
  _exports.default = ThreadModel;
});