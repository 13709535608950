define("@woody-lite/addon-feedback/repositories/feedback", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "@ember/application", "ember-object-data-model/models/base-array", "@ember/debug", "@woody-lite/addon-feedback/models/aggregated-feedback", "@ember/object"], function (_exports, _repository, _service, _application, _baseArray, _debug, _aggregatedFeedback, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let FeedbackRepository = _exports.default = (_class = class FeedbackRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    _getCachedModel(id) {
      return (0, _object.get)(this.store, 'content').find(model => model instanceof _aggregatedFeedback.default && model.id === String(id));
    }

    /**
     * Retrieves the feedback of a group of campaings.
     *
     * @param {SessionMode} session - the current session model
     * @param {Number[]} ids - an array of campaings IDs to retrieve it's feedback data
     * @return {Promise<('AggregatedFeedbackModel')>}
     */
    async getCrmCampaignsFeedback(session, ids) {
      (false && !(session) && (0, _debug.assert)('A session model is mandatory as first argument', session));
      (false && !(ids && ids.length) && (0, _debug.assert)('An array of IDs is mandatory as second argument', ids && ids.length));
      const cachedModels = [];
      const nonCachedIDs = [];
      ids.forEach(id => {
        const model = this._getCachedModel(id);
        if (model) {
          cachedModels.push(model);
        } else {
          nonCachedIDs.push(id);
        }
      });
      if (!nonCachedIDs.length) {
        return _baseArray.default.create({
          content: cachedModels
        });
      }
      const data = nonCachedIDs.map(id => ({
        behavioralEventForm: {
          id
        }
      }));
      const campaigns = await this.enax.POST('aggregated-feedback', data, {
        currentSession: session
      });
      const normalizer = (0, _application.getOwner)(this).lookup('normalizer:aggregated-feedback');
      const content = campaigns.data.items.map(normalizer.normalize).map(campaign => this.createEntry('aggregated-feedback', campaign.id, campaign));
      return _baseArray.default.create({
        content: [...content, ...cachedModels]
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});