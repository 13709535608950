define("@woody-lite/core-session/models/session/public", ["exports", "@woody-lite/core-session/models/session/base", "@woody-lite/core-session/decorators/-session-inactivity", "@ember/object/observers", "@ember/object"], function (_exports, _base, _sessionInactivity, _observers, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WINDOWS = _exports.TIMEOUTS = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const TIMEOUTS = _exports.TIMEOUTS = {
    ENAX: 300000,
    // 5 minutes
    INACTIVITY: 290000 // ~4.8 minutes
  };
  const WINDOWS = _exports.WINDOWS = {
    ENAX: 120000 // 2 minutes
  };
  const TIMER_ID = 'public-enax';
  let PublicModel = _exports.default = (_dec = (0, _sessionInactivity.default)(TIMEOUTS.INACTIVITY), _dec(_class = (_class2 = class PublicModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _observers.addObserver)(this, 'tsec', this, '_tsecDidChange');
    }
    async willDestroy() {
      (0, _observers.removeObserver)(this, 'tsec', this, '_tsecDidChange');
      super.willDestroy(...arguments);
    }
    _tsecDidChange() {
      if ((0, _object.get)(this, 'tsec')) {
        this.getTimer(TIMER_ID).run();
      } else {
        // Don't do a "logout", user can continue using the app without calling the backend.
        this._clearTimers();
      }
    }
    _createTimers() {
      super._createTimers(...arguments);
      this._createEnaxTimer();
    }
    _createEnaxTimer() {
      this._createTimer({
        id: TIMER_ID,
        callback: this._onEnaxTimeout,
        delay: TIMEOUTS.ENAX - WINDOWS.ENAX
      });
    }
    async keepOauthAlive() {
      await this.provider.push('session', 'loginWithOAuth', {
        app: 'public'
      });
      this.getTimer(TIMER_ID).run();
    }
    _onEnaxTimeout() {
      if (!(this.isDestroying || this.isDestroyed)) {
        this.keepOauthAlive();
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_onEnaxTimeout", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onEnaxTimeout"), _class2.prototype)), _class2)) || _class);
});