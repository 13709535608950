define("@woody-lite/addon-crm-campaigns/caass/campaign-detail-content-fragment", ["exports", "@woody-lite/core-network/resources/caas", "@ember/application", "@ember/service"], function (_exports, _caas, _application, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const SPANISH_LANGUAGE = 'es';
  const ITALY_COUNTRY = 'italia';
  let CampaignDetailContentFragmentCaas = _exports.default = (_class = class CampaignDetailContentFragmentCaas extends _caas.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get country() {
      var _config$crmCampaigns;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return (config === null || config === void 0 ? void 0 : (_config$crmCampaigns = config['crm-campaigns']) === null || _config$crmCampaigns === void 0 ? void 0 : _config$crmCampaigns.country) || 'spain';
    }
    get endpoint() {
      if (this.country === ITALY_COUNTRY || this.suffix === SPANISH_LANGUAGE) {
        return '/content/caas/:country/app/crm/campaigns.model.:id.json';
      }
      return '/:suffix/content/caas/:country/app/crm/campaigns.model.:id.json';
    }
    get suffix() {
      return this.intl.primaryLocale;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});