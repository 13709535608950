define("@woody-lite/addon-feedback/services/opinator-configuration", ["exports", "@ember/service", "@woody-lite/core-session/decorators/session"], function (_exports, _service, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let OpinatorConfigurationService = _exports.default = (_dec = (0, _session.default)('client'), (_class = class OpinatorConfigurationService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "currentSession", _descriptor2, this);
    }
    fetch(currentRoute, feedbackFormId, feedbackType) {
      try {
        const route = this.getCleanRoute(currentRoute);
        return this.fetchFeedback(route, feedbackFormId, feedbackType);
      } catch {
        return {};
      }
    }
    async fetchFeedback(route, feedbackFormId, feedbackType) {
      try {
        var _this$currentSession, _response$link;
        const customerId = (_this$currentSession = this.currentSession) === null || _this$currentSession === void 0 ? void 0 : _this$currentSession.id;
        const resourceEnax = customerId ? 'feedback-client' : 'feedback';
        const payload = {
          params: {
            customerId,
            useCustomerManager: false
          }
        };
        const data = await this.enax.GET("".concat(resourceEnax), payload);
        const response = this.getLinkRoute(data === null || data === void 0 ? void 0 : data.items, route, feedbackFormId, feedbackType);
        return response === null || response === void 0 ? void 0 : (_response$link = response.link) === null || _response$link === void 0 ? void 0 : _response$link.href;
      } catch {
        // Noop
      }
      return null;
    }
    getCleanRoute(currentRoute) {
      const splits = currentRoute.split('/');
      if (splits[1]) {
        return splits[1].substr(7);
      }
      return splits[0];
    }
    getLinkRoute(items, route, feedbackFormId, feedbackType) {
      var _form$links;
      const routeForm = items.find(item => item.id === route);
      const form = routeForm === null || routeForm === void 0 ? void 0 : routeForm.forms.find(f => f.id === feedbackFormId);
      return form === null || form === void 0 ? void 0 : (_form$links = form.links) === null || _form$links === void 0 ? void 0 : _form$links.find(l => {
        var _l$type;
        return (l === null || l === void 0 ? void 0 : (_l$type = l.type) === null || _l$type === void 0 ? void 0 : _l$type.id) === feedbackType;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});