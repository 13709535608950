define("@woody-lite/addon-non-financial-services/enaxs/non-financial-services", ["exports", "@woody-lite/core-session/resources/client-enax", "@woody-lite/core-session/decorators/session", "@woody-mrs-potato/core-http/services/http"], function (_exports, _clientEnax, _session, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NonFinancialServicesEnax = _exports.default = (_dec = (0, _session.default)('client'), (_class = class NonFinancialServicesEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_FIRST);
      _initializerDefineProperty(this, "currentSession", _descriptor, this);
      _defineProperty(this, "customerId", this.currentSession.id);
      _defineProperty(this, "endpoint", {
        GET: '/non-financial-services/v0/non-financial-services/',
        POST: '/non-financial-services/v0/non-financial-services/'
      });
      _defineProperty(this, "smc", {
        GET: 'SMC201800563',
        POST: 'SMC201800411'
      });
    }
    serialize(data) {
      if (this.method === 'GET') {
        return {
          targetUserId: this.currentSession.id,
          ...data
        };
      }
      return {
        ...data
      };
    }
    async done() {
      const response = await super.done(...arguments);
      return (response === null || response === void 0 ? void 0 : response.data) || {};
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});