define("@woody-lite/core-session/components/modal-signature-choices", ["exports", "@woody-lite/core-foundation/components/modal", "@ember/object", "@glimmer/tracking", "@woody-lite/core-session/templates/modal-signature-choices", "@ember/utils", "@ember/service"], function (_exports, _modal, _object, _tracking, _modalSignatureChoices, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const CONFIG = {
    '05': {
      title: 'label.withAnSMS',
      duration: 1
    },
    150: {
      title: 'label.withYourContactlessCard',
      text: 'label.rememberToHaveOneOfYourContactlessCardsHandly',
      duration: 1,
      linkText: 'label.whichCardsCanUse',
      modal: 'card-list'
    },
    259: {
      title: 'label.withSmsAndSelfie',
      text: 'label.withSmsAndSelfieDescription',
      duration: 1
    }
  };
  let ModalSignatureChoicesComponent = _exports.default = (_class = class ModalSignatureChoicesComponent extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalSignatureChoices.default);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "veil", _descriptor2, this);
      _initializerDefineProperty(this, "title", _descriptor3, this);
      _initializerDefineProperty(this, "description", _descriptor4, this);
      _initializerDefineProperty(this, "selectedMethod", _descriptor5, this);
      _initializerDefineProperty(this, "methods", _descriptor6, this);
      this.veil.remove(true);
    }
    get disableButton() {
      return !this.selectedMethod;
    }
    async init() {
      super.init(...arguments);
      this.methods = await this.getMethods();
    }
    async getMethods() {
      const device = await this.provider.pull('device', 'getDevice');
      const methods = this.model.options.methods || [];
      const preferMethod = device.isIOS ? '05' : '150';
      const existMethod = !(0, _utils.isEmpty)(methods.find(method => method === preferMethod));
      return methods.map((method, index) => {
        const recommended = existMethod ? method === preferMethod : index === 0;
        return {
          id: method,
          ...CONFIG[method],
          recommended
        };
      });
    }
    closeModal() {
      this.reject();
    }
    submit() {
      this.veil.add();
      this.resolve(this.selectedMethod);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "title", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'label.biometricSignature';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "description", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'label.toActivateTheBiometricSignature';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedMethod", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "methods", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
});