define("@woody-mrs-potato/addon-slinky/services/slinky", ["exports", "@woody-mrs-potato/addon-slinky/core/slinky", "@woody-mrs-potato/addon-slinky/wires/android", "@woody-mrs-potato/addon-slinky/wires/ios", "ember", "@ember/service", "@glimmer/tracking", "@ember/utils"], function (_exports, _slinky, _android, _ios, _ember, _service, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let pending = 0;

  // istanbul ignore else: this is always true on tests
  if (_ember.default.Test) {
    // eslint-disable-next-line ember/new-module-imports
    _ember.default.Test.registerWaiter(() => pending === 0);
  }
  let SlinkyService = _exports.default = (_class = class SlinkyService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "slinky", void 0);
      _initializerDefineProperty(this, "_isConnected", _descriptor, this);
      this.slinky = new _slinky.default();
      this.slinky.register(new _android.default());
      this.slinky.register(new _ios.default());
    }
    get isConnected() {
      return (0, _utils.isPresent)(this._isConnected) ? this._isConnected : this.slinky.isConnected;
    }
    set isConnected(value) {
      this._isConnected = value;
    }
    async send() {
      pending++;
      try {
        return await this.slinky.send(...arguments);
      } finally {
        pending--;
      }
    }
    on() {
      return this.slinky.on(...arguments);
    }
    off() {
      return this.slinky.off(...arguments);
    }
    trigger() {
      return this.slinky.trigger(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_isConnected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});