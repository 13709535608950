define("@woody-lite/core-session/decorators/akamai-retry", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = akamaiRetry;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const HTTP_FORBIDDEN = 403;
  function akamaiRetry() {
    return function (target) {
      var _class, _descriptor, _descriptor2;
      const klass = (_class = class _class extends target {
        constructor() {
          super(...arguments);
          _initializerDefineProperty(this, "slinky", _descriptor, this);
          _initializerDefineProperty(this, "akamaiRetry", _descriptor2, this);
        }
        async before() {
          if (this.akamaiRetry) {
            this.headers['x-ak-sn'] = 'v800001sn2';
          }
          return super.before(...arguments);
        }
        async fail() {
          try {
            return await super.fail(...arguments);
          } catch (e) {
            if (this.slinky.isConnected && e.status === HTTP_FORBIDDEN && this.retries < 1) {
              this.akamaiRetry = true;
              return this.retry({
                retries: 2
              });
            }
            this.akamaiRetry = false;
            throw e;
          }
        }
      }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: null
      }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "akamaiRetry", [_tracking.tracked], {
        configurable: true,
        enumerable: true,
        writable: true,
        initializer: function () {
          return false;
        }
      })), _class);
      Object.defineProperty(klass, 'name', {
        value: target.name
      });
      return klass;
    };
  }
});